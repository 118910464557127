import React from 'react';
import { View, Text, Image } from 'react-native';
import { List } from 'react-native-paper';
import { useBalance } from 'wagmi';

import USDIcon from '../assets/USD.png';
import EURIcon from '../assets/EUR.png';

import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

const tokenList = [
    {
        currency: "USD",
        desc: "Circle USDC",
        icon: USDIcon,
        address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    },
    {
        currency: "EUR",
        desc: "Tether EURT",
        icon: EURIcon,
        address: "0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f",
    },
];

// Custom hook to load balances
// export function useBalances() {
//     const [balances, setBalances] = React.useState([]);

//     const { primaryWallet } = useDynamicContext();

//     const usdBalance = useBalance({
//         address: primaryWallet?.address,
//         token: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
//         watch: true,
//     });

//     const eurBalance = useBalance({
//         address: primaryWallet?.address,
//         token: "0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f",
//         watch: true,
//     });

//     React.useEffect(() => {
//         setBalances([
//             {
//                 currency: "USD",
//                 desc: "Circle USDC",
//                 icon: USDIcon,
//                 balance: usdBalance,
//             },
//             {
//                 currency: "EUR",
//                 desc: "Tether EURT",
//                 icon: EURIcon,
//                 balance: eurBalance,
//             },
//         ]);

//         console.log("USD Balance: ", balances[0]?.balance.data?.formatted);
//         console.log("EUR Balance: ", balances[1]?.balance.data?.formatted);
//         console.log("Balances: ", balances);
//     }, [primaryWallet]);


//     return balances;
// }

export function useBalanceTotal() {
    const { primaryWallet } = useDynamicContext();
    const [totalBalance, setTotalBalance] = React.useState(0);
    const [isFetched, setIsFetched] = React.useState(false);

    // First useBalance hook call
    const { data: dataUSD, isError: isErrorUSD, isLoading: isLoadingUSD } = useBalance({
        address: primaryWallet?.address,
        token: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
        watch: true,
    });

    // Second useBalance hook call
    const { data: dataEUR, isError: isErrorEUR, isLoading: isLoadingEUR } = useBalance({
        address: primaryWallet?.address,
        token: "0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f",
        watch: true,
    });

    // Update total balance whenever data changes
    React.useEffect(() => {
        const balance1 = dataUSD?.formatted ? parseFloat(dataUSD.formatted) : 0;
        const balance2 = dataEUR?.formatted ? parseFloat(dataEUR.formatted) : 0;
        const newTotalBalance = balance1 + balance2;

        setTotalBalance(newTotalBalance);
        setIsFetched(!isLoadingEUR && !isLoadingUSD && !isErrorEUR && !isErrorUSD);

        // Logging the updated totalBalance inside the useEffect
        console.log("Total Balance: ", newTotalBalance);
        console.log("Is Fetched: ", !isLoadingEUR && !isLoadingUSD && !isErrorEUR && !isErrorUSD);
    }, [dataUSD, dataEUR, isLoadingUSD, isLoadingEUR, isErrorUSD, isErrorEUR]);

    return { totalBalance, isFetched };
}


function BalanceRow({token}) {
    const { primaryWallet } = useDynamicContext();
    const { data, isError, isLoading } = useBalance({
        address: primaryWallet?.address,
        token: token.address,
        watch: true,
    });

    return (
        <View style={{flex: 1, flexDirection: "row", justifyContent: "space-between"}} >
            <List.Item
                title={token.currency}
                description={token.desc}
                titleStyle={styles.subTotalTitle}
                descriptionStyle={styles.subTotalDesc}
                left={() => <Image source={token.icon} style={{ width: 32, height: 32, alignSelf: "center" }} />}
            />
            <Text style={styles.subTotalLabel} >
            {
                isLoading ? 'Loading...' :
                isError ? 'Error fetching balance' :
                `$ ${Number.parseFloat(data?.formatted).toFixed(2)}`
            }
            </Text>
        </View>
    );
}

export function BalanceDetail() {
    return (
        <View style={styles.subTotalScreen}>
        {
            tokenList.map((token) =>
                <BalanceRow token={token} key={token.currency} />
            )
        }
        </View>
    );
}

export function BalanceTotal() {
    const { totalBalance } = useBalanceTotal();

    return (
        <View style={styles.totalScreen}>
            <Text style={styles.totalLabel}>
                {`$ ${totalBalance.toFixed(2)}`}
            </Text>
        </View>
    );
}

const styles = {
    totalScreen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        minHeight: 100,
        maxHeight: 100,
    },
    totalLabel: {
        fontSize: 32,
        fontFamily: 'Inter, sans-serif',
    },
    subTotalScreen: {
        padding: 10,
    },
    subTotalLabel: {
        fontSize: 20,
        fontFamily: 'Inter, sans-serif',
        alignSelf: "center",
    },
    subTotalTitle: {
        fontSize: 20,
        fontFamily: 'Inter, sans-serif',
    },
    subTotalDesc: {
        fontSize: 12,
        fontFamily: 'Inter, sans-serif',
        color: "rgba(36,36,36,0.588)",
    },
};