import jsQR from "jsqr";
import * as ImagePicker from 'expo-image-picker';
import { IconButton } from "react-native-paper";

import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { TextInput, HelperText } from 'react-native-paper';

import { BarCodeScanner } from 'expo-barcode-scanner';

import { QRScan } from './QRScan';

async function decodeQRCode(imageUri) {
  try {
    // Create an image element
    const img = document.createElement('img');
    img.src = imageUri;

    // Wait for the image to load
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // // Create a canvas to draw the image
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;

    // Draw the image onto the canvas
    context.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Get image data from canvas
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    // Decode the QR code using jsQR
    const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

    console.log(imageData.data);

    if (!qrCode) {
        console.log("No QR code detected");
        // Display the loaded image for debugging
        return { qrCode: null, error: "No QR code detected" };
    }

    return { qrCode: qrCode.data, error: null };
  } catch (error) {
    console.log("Error decoding QR code: ", error);
    return { qrCode: null, error: error.message };
  }
}

function extractEthereumAddress(inputString) {
  if (!inputString) return inputString; // Returns null or empty if input is null or empty

  const parts = inputString.split(':');
  const address = parts.find(part => part.startsWith('0x'));

  return address || inputString; // Returns the part starting with '0x' or the original string
}

export function AddressInput({ value, setValue }) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  // const [type, setType] = useState(CameraType.back);
  const [showCamera, setShowCamera] = useState(false);

  const pickImage = async () => {
    try {
        let result = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: false,
          quality: 1,
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
        });

        if (!result.canceled) {
            // Decode QR code from the resized and compressed image
            const { qrCode, error } = await decodeQRCode(result.assets[0].uri);
            if (qrCode) {
              onChange(qrCode);
              setErrorMessage(null); // Clear previous errors if any
            } else {
              onChange(""); // Reset or handle accordingly
              if (error) {
                  setErrorMessage(error);
              } else {
                  setErrorMessage("Unknown error while decoding QR code.");
              }
            }
        }
    } catch (error) {
      console.log(error);
      setErrorMessage("An error occurred while picking the image.");
      onChange(""); // Reset or handle accordingly
    }
  };

  const onChange = (text) => {
    setValue(extractEthereumAddress(text));
  }

  const onScan = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    if(status === 'granted'){
      setErrorMessage(null);
      setShowCamera(true);
    }else{
      setShowCamera(false);
      setErrorMessage("No access to camera");
      onChange(""); // Reset or handle accordingly
    }
  }

  const onScanCancel = () => {
    setErrorMessage(null);
    setShowCamera(false);
  }

  return (
    <View>
      <View style={[
        {flexDirection: "row", justifyContent: 'space-between', alignItems: 'flex-end'},
      ]}>
          <TextInput
            style={[
              {backgroundColor:'white', textAlign: "left", flexGrow:1 },
              styles.inputText,
            ]}
            placeholder="Enter an address or a QR code"
            label="To"
            onChangeText={onChange}
            value={value}
            textColor='black'
            underlineColor='white'
            activeUnderlineColor="white"
          />
          <IconButton size={20} icon="qrcode-scan" onPress={onScan} iconColor="#000000" />
          <IconButton size={20} icon="upload" onPress={pickImage} iconColor="#000000" />
      </View>
      <View>
      <HelperText type="info" visible={value !== ""} >
        {value}
      </HelperText>
      <HelperText type="error" visible={!!errorMessage} style={{color: "#ef5350"}}>
        {errorMessage}
      </HelperText>
      <QRScan visible={showCamera} hideModal={onScanCancel} onQRScanned={onChange} />
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  camera: {
    flex: 1,
    aspectRatio: 1,
  },
  g4kvt: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingHorizontal: 2,
  },
  inputText: {
    fontSize: 16,
    fontFamily: 'Inter, sans-serif',
  },
});
