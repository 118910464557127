import * as React from 'react';
import { SafeAreaView, SafeAreaProvider } from 'react-native-safe-area-context';
import {
  Image,
  ScrollView,
  Animated,
  StyleSheet,
  View,
} from 'react-native';
import {
  Provider as PaperProvider,
  Appbar,
  Text,
  MD3DarkTheme,
  MD3LightTheme,
  useTheme,
  overlay,
  List,
  Portal,
} from 'react-native-paper';
import {
  Tabs,
  TabScreen,
  TabsProvider,
} from 'react-native-paper-tabs';

import {
  DynamicEmbeddedWidget,
  DynamicContextProvider,
  DynamicUserProfile,
  useDynamicContext,
} from '@dynamic-labs/sdk-react-core';
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { EthersExtension } from "@dynamic-labs/ethers-v5";

import { SwapWidget } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css'

import { AppMenu } from '../components/AppMenu';

import {
  ZeroDevSmartWalletConnectors,
} from "@dynamic-labs/ethereum-aa";

import { BalanceDetail, BalanceTotal, useBalanceTotal } from './../components/Balance';
import { Welcome } from './../components/Welcome';

// const JSON_RPC_URL = "https://polygon-rpc.com";
const JSON_RPC_URL = 'https://polygon-mainnet.infura.io/v3/026d566726b14294a613686c46ac40bf'

const MY_TOKEN_LIST = [
  {
    "name": "USD",
    // "address": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "address": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "symbol": "USD",
    "decimals": 6,
    "chainId": 137,
    "logoURI": "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694"
  },
  {
    "name": "EUR",
    "address": "0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f",
    "symbol": "EUR",
    "decimals": 6,
    "chainId": 137,
    "logoURI": "https://assets.coingecko.com/coins/images/17385/large/Tether_full_logo_dm.png?1627537298"
  },
]

const evmNetwork = [
  {
    blockExplorerUrls: ['https://polygonscan.com/'],
    chainId: 137,
    chainName: 'Matic Mainnet',
    iconUrls: ["https://app.dynamic.xyz/assets/networks/polygon.svg"],
    name: 'Polygon',
    nativeCurrency: {
      decimals: 18,
      name: 'MATIC',
      symbol: 'MATIC',
    },
    networkId: 137,
    rpcUrls: ['https://polygon-rpc.com'],
    vanityName: 'Polygon',
    privateCustomerRpcUrls: [
      {
        url: JSON_RPC_URL,
        webSocket: "wss://polygon-mainnet.infura.io/v3/026d566726b14294a613686c46ac40bf"
      }
    ],
  },
];

function formatBalance(usdtBalance, eurtBalance) {
  const usd = Number.parseFloat(usdtBalance?.formatted) * 1.00;
  const eurusd = Number.parseFloat(eurtBalance?.formatted) * 1.08 * 1.00;
  const totalBalance = (usd + eurusd).toFixed(2);
  return `$${totalBalance}`;
}

function App({
  onToggleDarkMode,
  dark,
}: {
  onToggleDarkMode: () => any;
  dark: boolean;
}) {
  return (
    <DynamicContextProvider
        settings={{
          environmentId: '7811cc4e-b35e-49b7-9792-08385761b06e',
          walletConnectors: [ EthereumWalletConnectors, ZeroDevSmartWalletConnectors ],
          walletConnectorExtensions: [ EthersExtension ],
          cssOverrides: `
          [data-testid="send-balance-button"] {
            display: none;
          }`,
        }}
    >
      <DynamicWagmiConnector settings={{ evmNetworks: evmNetwork }}>

        <AppContainer onToggleDarkMode={onToggleDarkMode} dark={dark} />
        <>
          <DynamicUserProfile />
        </>
      </DynamicWagmiConnector>
    </DynamicContextProvider>
  );
}

function AppContainer({
  onToggleDarkMode,
  dark,
}: {
  onToggleDarkMode: () => any;
  dark: boolean;
}) {
  const [backgroundColor, setBackgroundColor] = React.useState<
    string | undefined
  >(undefined);
  const [uppercase, setUppercase] = React.useState<boolean>(true);
  const [showText, setShowText] = React.useState<boolean>(true);
  const [iconPosition, setIconPosition] = React.useState<'leading' | 'top'>(
    'leading'
  );
  const [mode, setMode] = React.useState<'fixed' | 'scrollable'>('fixed');

  const theme = useTheme();
  const bg =
    theme.dark && theme.mode === 'adaptive'
      ? overlay(3, theme.colors.surface)
      : (theme.colors.surface as any);

  const tabProps = {
    uppercase, // true/false | default=true | labels are uppercase
    showTextLabel: showText, // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
    iconPosition, // leading / top
    style: !dark ? { backgroundColor } : undefined, // works the same as AppBar in react-native-paper
    mode, // fixed, scrollable | default=fixed}
    showLeadingSpace: true, // show leading space in scrollable tabs inside the header
  };

  function handleChangeIndex(index: number) {
    console.log('Tab Index:', index);
  }

  const { setShowDynamicUserProfile, primaryWallet, user } = useDynamicContext();
  const { totalBalance, isFetched } = useBalanceTotal();

  const [provider, setProvider] = React.useState(null);

  // const balances = useBalances();

  React.useEffect(() => {
    const retrieveProvider = async () => {
      try {
        if (primaryWallet && primaryWallet.connector && primaryWallet.connector?.ethers) {
          const _signer = await primaryWallet?.connector?.ethers?.getSigner();
          setProvider(_signer?.provider);
          console.log("Provider retrieved:", provider);
        } else {
          // Handle the case where ethers is not available
          console.log("Ethers object is not available on the connector.");
        }

      } catch (error) {
        // Handle any errors that occur during getSigner
        console.log("Error retrieving signer:", error);
      }
    };

    retrieveProvider();
  }, [primaryWallet]); // Dependency array: re-run this effect if primaryWallet changes

  return (
      <SafeAreaView
        style={[styles.full, { backgroundColor: theme.colors.background }]}
      >

        {
          (!primaryWallet) ?

            <DynamicEmbeddedWidget background="default" />

          :

            <ScrollView style={styles.root}>

            <Animated.View
              style={[
                styles.content,
                styles.contentInline,
                { backgroundColor: "white", maxWidth: 400 },
              ]}
            >
              <Appbar style={{ backgroundColor: "white" }}>
                <Appbar.Action icon="account" onPress={() => setShowDynamicUserProfile(true)} iconColor='#7E64ED' />
                <Appbar.Content title={user?.email} titleStyle={
                  { color: "rgba(36,36,36,0.588)", fontFamily: "Inter, sans-serif", fontSize: 16, fontWeight: "300"}
                } />
              </Appbar>

              <BalanceTotal />

              <AppMenu />

              <Enter />

              <TabsProvider defaultIndex={0} onChangeIndex={handleChangeIndex}>
                <Tabs {...tabProps}>
                  {totalBalance == 0 && isFetched &&
                    <TabScreen label="Welcome" icon={"home"}>
                      <View style={{paddingHorizontal: 20, paddingVertical: 10, height: 400}}>
                        <Welcome />
                      </View>
                    </TabScreen>
                  }
                  <TabScreen label="Balances" icon={"account-cash"}>
                    <View style={{paddingHorizontal: 5, paddingVertical: 10, height: 350 }}>
                      <BalanceDetail />
                    </View>
                  </TabScreen>
                  <TabScreen label="Convert" icon={"swap-horizontal"}>
                    <View style={{paddingHorizontal: 5, paddingVertical: 10, height: 350 }}>
                      {provider && (
                        <SwapWidget
                          jsonRpcEndpoint={JSON_RPC_URL}
                          tokenList={MY_TOKEN_LIST}
                          provider={provider}
                          defaultInputTokenAddress={MY_TOKEN_LIST[1].address}
                          defaultInputAmount="1"
                          defaultOutputTokenAddress={MY_TOKEN_LIST[0].address}
                          theme={swapTheme}
                        />
                      )}
                    </View>
                  </TabScreen>
                </Tabs>
              </TabsProvider>

            </Animated.View>
            </ScrollView>
        }

      </SafeAreaView>
  );
}

const swapTheme = {
  primary: '#000',
  secondary: '#666',
  interactive: '#FFF',
  container: '#FFF',
  module: '#FFF',
  accent: '#7E64ED',
  outline: '#FFF',
  dialog: '#FFF',
  borderRadius: 0.8,
  fontFamily: 'Inter, sans-serif',
}

function Enter() {
  return <View style={styles.enter} />;
}

export default function AppWithProviders() {
  const [dark, setDark] = React.useState(false);
  const onToggleDarkMode = () => {
    setDark((prev) => !prev);
  };

  return (
    <PaperProvider theme={dark ? MD3DarkTheme : MD3LightTheme}>
      <SafeAreaProvider>
        <App onToggleDarkMode={onToggleDarkMode} dark={dark} />
      </SafeAreaProvider>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  screenWithText: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'pink',
    height: 100,
    minHeight: 100,
    maxHeight: 100,
  },
  full: { flex: 1 },
  underline: { textDecorationLine: 'underline' },
  logo: { width: 56, height: 56, marginRight: 24 },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  twitterButton: { marginBottom: 16 },
  root: { flex: 1 },
  padding: { padding: 16 },
  content: {
    width: '100%',
    maxWidth: 500,
    marginTop: 16,
    alignSelf: 'center',
    // flex: 1,
  },
  contentInline: {
    padding: 0,
    height:"100%",

  },
  enter: { height: 12 },
  label: { width: 100, fontSize: 16, flex: 1 },
  row: {
    padding: 16,
    paddingLeft: 16,
    paddingRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  balanceRow: {
    flexDirection: 'row',
    fontFamily: 'Inter, sans-serif',
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24,
  },

  buHiEt: {
    alignItems: 'center',
    display: 'flex',  // React Native uses flexbox by default, but the 'display' property is not used
    flexWrap: 'wrap',
    flexDirection: 'row', // 'grid' and 'auto-flow' are not supported, use 'row' for a horizontal layout
    justifyContent: 'space-between',
  },

  idmvxm: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row', // This replaces display: grid and flex-flow: wrap
    flexWrap: 'wrap',
  },

    epqtan: {
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 16,
      fontFamily: 'InterVariable, sans-serif',
    },
    g4kvt: {
      fontSize: 16,
      fontWeight: "400", // React Native supports numeric font-weight
      lineHeight: 16,
      fontFamily: 'InterVariable, sans-serif',
      // color: 'YourColor', // Again, replace 'YourColor' with the actual color value
    },
    big: {
      fontSize: 32,
      fontWeight: "400", // React Native supports numeric font-weight
      lineHeight: 32,
      fontFamily: 'InterVariable, sans-serif',
      // color: 'YourColor', // Again, replace 'YourColor' with the actual color value
    },

    largeText: {
      fontSize: 32,
      fontWeight: "400", // React Native supports numeric font-weight
      lineHeight: 32,
      fontFamily: 'InterVariable, sans-serif',
      // color: 'YourColor', // Again, replace 'YourColor' with the actual color value
    },
    mediumText: {
      fontSize: 20,
      fontWeight: "500", // React Native supports numeric font-weight
      lineHeight: 20,
      fontFamily: 'InterVariable, sans-serif',
      // color: 'YourColor', // Again, replace 'YourColor' with the actual color value
    },

    swapBlock: {
      flex: 1,
      alignItems: 'center',
      fontFamily: 'Inter, sans-serif',
      fontSize: 14,
    },

    qrCode: {
      flex: 1,
      alignItems: 'center',
      padding: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    header: {
      fontSize: 24,
      fontWeight: 'normal',
      color: '#000000',
      marginBottom: 8,
      fontFamily: 'Inter, sans-serif',
    },
    subHeader: {
      color: '#000000',
      marginBottom: 16,
    },
    button: {
      maxWidth: 200, // Adjust based on your layout
      backgroundColor: '#7E64ED',
      padding: 16,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
    subTotalLabel: {
      fontSize: 16,
      fontFamily: 'Inter, sans-serif',
      alignSelf: "center",
    },
});

