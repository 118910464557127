import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, Share } from 'react-native';
import { Portal, Dialog, Button, IconButton, Modal, Text } from 'react-native-paper';

import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import QRCodeStyling from "qr-code-styling";

export function QRCode({ visible, onDismiss }) {
  const { primaryWallet } = useDynamicContext();
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 200,
      height: 200,

      dotsOptions: {
        color: "black",
        type: "extra-rounded",
      },

      cornersDotOptions: {
        color: "black",
        type: "",
      },

      cornersSquareOptions: {
        color: "black",
        type: "extra-rounded",
      },

      qrOptions: {
        errorCorrectionLevel: "H",
      },
    });

    qrCode.update({
      data: primaryWallet?.address,
    });

    qrCode.append(ref);
  }, [primaryWallet, ref]);

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: primaryWallet?.address,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log("Error sharing address: ", error);
    }
  };

  const containerStyle = {backgroundColor: 'white', padding: 20};

  return (
    <Portal>
    <Dialog visible={visible} onDismiss={onDismiss} style={{backgroundColor: '#ffffff', borderRadius: 10}}>
        <Dialog.Title style={
          { color: "#7E64ED", fontFamily: "Inter, sans-serif", fontSize: 17, fontWeight: "500", lineHeight: 24.5, letterSpacing: 2.8}}
        >RECEIVE MONEY</Dialog.Title>
        <Dialog.Content>
          <View>
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10}}>
              <Text style={styles.text} numberOfLines={1} >{primaryWallet?.address}</Text>
              <IconButton size={10} icon="content-copy" onPress={onShare} iconColor="rgba(36,36,36,0.588)" />
            </View>
            <Image ref={setRef} />
          </View>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
}

const styles = StyleSheet.create({
    qrContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 5,
      paddingVertical: 5,
    },
    qrDialog: {
      backgroundColor: "white",
      paddingHorizontal: 0,
      paddingVertical: 0,
    },
    text: {
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
      color: "rgba(36,36,36,0.588)",
    },
  });
