import * as React from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';
import {
  Text,
} from 'react-native-paper';
import { Svg, Path, Polyline } from 'react-native-svg';

export function Welcome() {
    return (
      <View
        style={[
          styles.content,
          styles.contentInline,
          { backgroundColor: "white" },
          { alignItems: 'center' },
        ]}>
        <HomeIcon style={styles.homeIcon} />
        <Text style={styles.header}>Welcome to Flexibo!</Text>
        <Enter />
        <View style={styles.stepContainer}>
          <View style={styles.step}>
            <Text style={styles.stepNumber}>1.</Text>
            <Text style={styles.stepText}>
            Click Buy to top up your account. You'll be redirected to Coinbase, where you can purchase USDC. If you don't have an account, you can sign up for one.
            </Text>
          </View>
          <View style={styles.step}>
            <Text style={styles.stepNumber}>2.</Text>
            <Text style={styles.stepText}>Send money instantaneously and globaly. Or click Receive to share your QR code and request money.</Text>
          </View>
          <View style={styles.step}>
            <Text style={styles.stepNumber}>3.</Text>
            <Text style={styles.stepText}>On the Convert tab you'll be able to do FX conversion. You'll need MATIC for transaction fees, you can purchase some by clicking Buy. </Text>
          </View>
        </View>
        <Enter />
      </View>
    );
  }

  function HomeIcon(props) {
    return (
      <Svg {...props} viewBox="0 0 24 24" fill="none">
        <Path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" stroke="#7E64ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Polyline points="9 22 9 12 15 12 15 22" stroke="#7E64ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </Svg>
    );
  }

  function Enter() {
    return <View style={styles.enter} />;
  }

  const styles = StyleSheet.create({
    content: {
        width: '100%',
        maxWidth: 500,
        marginTop: 16,
        alignSelf: 'center',
        // flex: 1,
      },
      contentInline: {
        padding: 0,
        height:"100%",

      },
      homeIcon: {
        height: 48,
        width: 48,
        marginBottom: 16, // Adjust based on your layout
      },
      header: {
        fontSize: 24,
        fontWeight: 'normal',
        color: '#000000',
        marginBottom: 8,
        fontFamily: 'Inter, sans-serif',
      },
      stepContainer: {
        width: '100%',
        maxWidth: 300, // Adjust based on your layout
      },
      step: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: 16,
      },
      stepNumber: {
        color: '#7E64ED',
        fontWeight: 'bold',
        fontSize: 20,
        marginRight: 8,
      },
      stepText: {
        flex: 1,
        color: '#000000',
        fontFamily: 'Inter, sans-serif',
      },
      enter: { height: 12 },
  });
