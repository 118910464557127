
import { Camera } from "expo-camera";
import { useRef, useState, useEffect } from "react";
import {
  Button,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { Modal, Portal, HelperText, Appbar } from "react-native-paper";
import { BarCodeScanner } from 'expo-barcode-scanner';


export function QRScan({ onQRScanned, visible, hideModal}) {

    const [scanned, setScanned] = useState(false);
    const [type, setType] = useState(Camera.Constants.Type.back);
    const cameraRef = useRef(null);

    const containerStyle = {backgroundColor: 'white', padding: 20, width: "100%", height: "100%"};

    const handleBarCodeScanned = ({ type, data }) => {
        console.log(`Bar code with type ${type} and data ${data} has been scanned!`);

        setScanned(true);
        onQRScanned(data);
        hideModal();
    };

    return (
        <Portal>
            <Modal
                visible={visible}
                onDismiss={hideModal}
                contentContainerStyle={containerStyle}

            >
            <Appbar.Header>
                <Appbar.BackAction onPress={hideModal} />
                <Appbar.Content title="Back" />
            </Appbar.Header>

                <Camera
                    style={{ flex: 1 }}
                    type={type}
                    ref={cameraRef}
                    onBarCodeScanned={handleBarCodeScanned}
                    barCodeScannerSettings={{
                        barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                    }}
                >
                </Camera>

            </Modal>
        </Portal>
    );
  }
