import { AddressInput } from './AddressInput';
import { AmountInput } from './AmountInput';

import * as React from 'react';

import { useDebounce } from 'use-debounce';

import { View, StyleSheet } from 'react-native';
import { Button, Portal, ActivityIndicator, Text, Icon, Modal, Appbar } from 'react-native-paper';

import { parseUnits, encodeFunctionData } from "viem";

import { useDynamicContext } from "@dynamic-labs/sdk-react-core";

import {
  isZeroDevConnector,
} from "@dynamic-labs/ethereum-aa";

const contractABI = [
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"to",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"value",
        "type":"uint256"
      }
    ],
    "name":"transfer",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":
    "function"
  },
];

function Enter() {
  return <View style={styles.enter} />;
}

function StatusIndicator({ isError, isLoading, isSuccess, error, txHash }) {
  console.log("Tx Hash: ", txHash);
  console.log("Error: ", error);
  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      {isLoading && <ActivityIndicator animating={true} color="#7E64ED" size={100}/>}

      {isError && <Icon source="alert-circle-outline" size={100} color="#ef5350"  />}
      {isSuccess && <Icon source="check-circle-outline" size={100} color="#4caf50"  />}

      {isError && <Text style={{ color: '#ef5350', flexWrap: "wrap", width: 200, textAlign: "center" }}>{error}</Text>}
      {isSuccess && <Text style={{ color: '#4caf50', flexWrap: "wrap", width: 200, textAlign: "center" }}>Transaction Hash: {txHash}</Text>}
    </View>
  );
};

export function Send( { visible, hideDialog } ) {
  const [to, setTo] = React.useState("");
  const [debouncedTo] = useDebounce(to, 500);

  const [amount, setAmount] = React.useState("");
  const [debouncedAmount] = useDebounce(amount, 500);

  const [address, setAddress] = React.useState("0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"); // USDC Polygon

  const [hash, setHash] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [pendingTx, setPendingTx] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [error, setError] = React.useState("");

  const { primaryWallet } = useDynamicContext();


  const isPositiveNumber = (input) => {
    const numericRegex = /^\d*\.?\d+$/;
    if (numericRegex.test(input)) {
      const number = parseFloat(input);
      return number > 0;
    }
    return false;
  }

  const send = async () => {
    console.log("send pressed");
    console.log("primary wallet address: ", primaryWallet.address);

    setPendingTx(true);
    setError(false);
    setIsError(false);
    setSuccess(false);

    if (!primaryWallet) {
      return;
    }

    const { connector } = primaryWallet;

    // Use the isZeroDevConnector type guard to check for the ZeroDevConnector
    if (!isZeroDevConnector(connector)) {
      console.log("connector: ", connector);
      return;
    }

    // Export the ZeroDev ECDSAProvider from the connector
    const ecdsaProvider = connector.getAccountAbstractionProvider();

    if (!ecdsaProvider) {
      return;
    }

    console.log("sendUserOperation");

    try {
      console.log("UserOp started");
      console.log("to: ", debouncedTo);
      console.log("amount: ", debouncedAmount);
      console.log("address: ", address);

      const { hash } = await ecdsaProvider.sendUserOperation([
        {
          data: encodeFunctionData({
            abi: contractABI,
            args: [
              debouncedTo,
              isPositiveNumber(debouncedAmount) ? parseUnits(debouncedAmount, 6) : undefined
            ],
            functionName: "transfer",
          }),
          target: address,
        },
      ]);

      console.log("userOp hash:", hash)
      console.log("UserOp completed")

      setHash(hash);
      setSuccess(true);
      setPendingTx(false);
    } catch(error) {
      setIsError(true);
      setError(error?.shortMessage || error?.message || "An error occurred while sending the transaction.");
      setPendingTx(false);
    }
  };

  const containerStyle = {backgroundColor: 'white', flex: 1};

  return (
    <Portal>
    <Modal
        visible={visible}
        onDismiss={hideDialog}
        contentContainerStyle={containerStyle}
    >
      <Appbar style={{ backgroundColor: "white" }}>
        <Appbar.Content title="SEND MONEY" titleStyle={
          { color: "#7E64ED", fontFamily: "Inter, sans-serif", fontSize: 21, fontWeight: "500", lineHeight: 36.75, letterSpacing: 4.8}
        } />
        <Appbar.Action icon="close" onPress={hideDialog} iconColor='rgba(36,36,36,0.588)' />
      </Appbar>


      <Enter />

      <View style={{ flex: 1, flexDirection: 'column', backgroundColor: 'white', padding: 20}}>
        <AmountInput value={amount} onValueChange={setAmount} onCurrencyChange={setAddress} />
        <Enter />
        <AddressInput value={to} setValue={setTo} />
        <Enter />
        <StatusIndicator isError={isError} isLoading={pendingTx} isSuccess={success} error={error} txHash={hash} />
        <Enter />
        <Button
          mode="contained"
          onPress={send}
          style={
            [styles.button, styles.buttonEnabled]
          }
        >
          Send
        </Button>
      </View>

      </Modal>
    </Portal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignContent:"center",
  },
  enter: {
    margin: 20,
  },
  largeText: {
    fontSize: 32,
    fontFamily: 'Inter, sans-serif',
  },
  mediumText: {
    fontSize: 20,
    fontWeight: "500", // React Native supports numeric font-weight
    lineHeight: 20,
    fontFamily: 'InterVariable, sans-serif',
// color: 'YourColor', // Again, replace 'YourColor' with the actual color value
  },
  button: {
    justifyContent: 'center', // Assuming center alignment, as React Native doesn't support CSS variables
    alignItems: 'center',
    textAlign: 'center',

    height: 54, // Assuming 1rem = 16px, 3.375rem ≈ 54px
    paddingHorizontal: 27, // 1.6875rem ≈ 27px

    fontFamily: 'Inter', // Custom fonts need to be loaded in React Native
    fontSize: 16, // Assuming 1em ≈ 16px
    fontWeight: '300',
    borderRadius: 6, // Assuming 0.375rem ≈ 6px

    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  buttonEnabled: {
    backgroundColor: '#7E64ED',
    color: '#FFFFFF',
  },

  appBar: {
      backgroundColor: 'white',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
  },
});
