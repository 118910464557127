import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { View } from 'react-native';
import { IconButton, Text, Button } from 'react-native-paper';
import { initOnRamp } from '@coinbase/cbpay-js';
import { Send } from "./Send";
import { QRCode, WalletQRCodeShareDialog } from './QRCodeDialog';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

import 'react-native-url-polyfill/auto'

const styles = {
    Container: {
        padding: 0, // Add padding around the container
    },
  Card: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginLeft: 30,
    marginRight: 30,
  },
  Button: {
    // color: '#000000',
    // backgroundColor: "#7e64ed",
    // backgroundColor: "#846BEE",
  },
  ButtonContainer: {
    alignItems: 'center',
  },
  ButtonText: {
    color: '#000000',
    fontFamily: "inter, sans-serif",
  },
};

export function AppMenu() {
    const [isReady, setIsReady] = useState(false);
    const [isSendModalVisible, setIsSendModalVisible] = useState(false);
    const [isQRCodeDialogVisible, setIsQRCodeDialogVisible] = useState(false);
    const onrampInstance = useRef();
    const { primaryWallet } = useDynamicContext();

    useEffect(() => {
        //initOnRamp parameters
        const options = {
            appId: '1dbd2a0b94',
            target: '#cbpay-container',
            defaultAsset: 'USDC', // Specify USDCs
            widgetParameters: {
                destinationWallets: [{
                    address: primaryWallet?.address,
                    blockchains: ["polygon"],
                    assets: ["USDC"],
                    supportedNetworks: ["polygon"],
                }],
            },
            onSuccess: () => {
                // handle navigation when user successfully completes the flow
            },
            onExit: () => {
                // handle navigation from dismiss / exit events due to errors
            },
            onEvent: (event) => {
                // event stream
                console.log(event);
            },
            experienceLoggedIn: 'embedded',
            experienceLoggedOut: 'popup',
        };

        // instance.destroy() should be called before initOnramp if there is already an instance.
        if (onrampInstance.current) {
            onrampInstance.current.destroy();
        }

        initOnRamp(options, (error, instance) => {
            if (instance) {
                onrampInstance.current = instance;
                setIsReady(true);
            }
        });
    }, [primaryWallet])

    const buyHandler = () => {
        onrampInstance.current.open();
    }

    const sendHandler = () => {
        setIsSendModalVisible(true);
    }

    const hideDialog = () => setIsSendModalVisible(false);
    return (
        <View style={styles.Container}>
            <View style={styles.Card}>
                <View style={styles.ButtonContainer}>
                    <IconButton
                        size={20}
                        icon="arrow-down"
                        onPress={buyHandler}
                        mode='outlined'
                        iconColor='#7e64ed'
                        loading={!onrampInstance.current}
                    />
                    <Text variant='labelLarge' style={styles.ButtonText}>Buy</Text>
                </View>
                <View style={styles.ButtonContainer}>
                    <IconButton
                        size={20}
                        icon="arrow-up"
                        onPress={sendHandler}
                        mode='outlined'
                        iconColor='#7e64ed'
                    />
                    <Text variant='labelLarge' style={styles.ButtonText}>Send</Text>
                    <Send visible={isSendModalVisible} hideDialog={hideDialog} />
                </View>
                <View style={styles.ButtonContainer}>
                    <IconButton
                        size={20}
                        icon="qrcode-scan"
                        onPress={() => setIsQRCodeDialogVisible(true)}
                        mode='outlined'
                        iconColor='#7e64ed'
                    />
                    <Text variant='labelLarge' style={styles.ButtonText}>Receive</Text>
                    <QRCode visible={isQRCodeDialogVisible} onDismiss={() => setIsQRCodeDialogVisible(false)} />
                </View>
            </View>
        </View>
    );
};
