import * as React from 'react';

import { Image, View, StyleSheet } from 'react-native';
import { List, TextInput } from 'react-native-paper';

import USDIcon from '../assets/USD.png';
import EURIcon from '../assets/EUR.png';

export function AmountInput({ value, onValueChange, onCurrencyChange }) {

  const [expanded, setExpanded] = React.useState(false);
  const [currency, setCurrency] = React.useState("USD");

  const handlePress = () => setExpanded(!expanded);

  const handleCurrencyChange = () => {
    if (currency == 'USD') {
      setCurrency('EUR');
      onCurrencyChange("0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f");
    } else {
      setCurrency('USD');
      onCurrencyChange("0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359");
    }

    setExpanded(false);
  };

  return (
    <View>
    <View style={[
      {flexDirection: "row", justifyContent: 'space-between', alignItems: 'flex-end'},
    ]}>
        <TextInput
          style={[
            {backgroundColor:'white', textAlign: "left", flexGrow:1, width: "50%" },
            styles.largeText,
          ]}
          value = {value}
          onChangeText={(entryValue) => {onValueChange(entryValue.replace(",", "."))}}
          placeholder="0"
          textColor='black'
          underlineColor='white'
          activeUnderlineColor="white"
          keyboardType="decimal-pad"
          />
      <List.Section style={{ backgroundColor:"white" }}>
        <List.Accordion
          title={currency}
          left={props => <List.Icon {...props} icon={() => <Image source={currency == "USD" ? USDIcon : EURIcon} style={{ width: 24, height: 24 }} />} />}
          expanded={expanded}
          onPress={handlePress}
          titleStyle={styles.mediumText}
          descriptionNumberOfLines={1}
          style={{backgroundColor: "white", padding: 0, margin: 0}}
          >
          <List.Item
            title={currency == 'USD' ? 'EUR' : 'USD'}
            onPress={() => handleCurrencyChange()}
            left={props => <List.Icon {...props} icon={() => <Image source={currency == "USD" ? EURIcon : USDIcon} style={{ width: 24, height: 24 }} />} />}
            titleStyle={styles.mediumText}
            style={{backgroundColor: "white", padding: 0, margin: 0}}
          />
        </List.Accordion>
      </List.Section>
    </View>
    </View>
  );
}

const styles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignContent:"center",
    },
    enter: {
      margin: 20,
    },
    largeText: {
      fontSize: 32,
      fontFamily: 'Inter, sans-serif',
    },
    mediumText: {
      fontSize: 20,
      fontWeight: "500", // React Native supports numeric font-weight
      lineHeight: 20,
      fontFamily: 'InterVariable, sans-serif',
  // color: 'YourColor', // Again, replace 'YourColor' with the actual color value
    },
});
